// extracted by mini-css-extract-plugin
export const background = "#fff";
export const foreground = "#000";
export const themeRed = "#ea291e";
export const medium = "500px";
export const desktop = "1000px";
export const large = "1400px";
export const banner = "HomeBanner-module--banner--1nC6y";
export const canvas = "HomeBanner-module--canvas--acz0g";
export const video = "HomeBanner-module--video--2XJQv";
export const headlinesWrapper = "HomeBanner-module--headlinesWrapper--2hJKl";
export const headline = "HomeBanner-module--headline--1Pt5v";
export const tagline = "HomeBanner-module--tagline--3zPRG";