import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames/bind"

import * as s from "./Text.module.scss"
const cn = classNames.bind(s)

// Headline Hero
export const HeadlineHero = ({ className, as = "h1", ...props }) => {
  const Element = as
  return <Element className={cn("headlineHero", className)} {...props} />
}

HeadlineHero.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
}
