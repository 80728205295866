import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"

import useStore from "context/ZustandStore"

import { HeadlineHero } from "components/Text"
import video from "assets/video/video.mp4"

import * as s from "./HomeBanner.module.scss"
import { useViewportScroll, useTransform, motion } from "framer-motion"

const _ = {
  title: "scroll",
  lines: ["here", "to", "stay", "and", "you", "???", "¯_(ツ)_/¯"],
}

const wrapper = {
  animate: {
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const outer = {
  initial: {
    x: "-200%",
    opacity: 0,
  },
  animate: {
    x: "0%",
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.5, 0.02, 0.2, 0.9],
    },
  },
}

const inner = {
  initial: {
    x: "190%",
  },
  animate: {
    x: "0%",
    transition: {
      duration: 1,
      ease: [0.5, 0.02, 0.2, 0.9],
      delay: 0.2,
    },
  },
}

const HomeBanner = () => {
  // const [shouldAnimate, setShouldAnimate] = useState(false)
  const currentTheme = useStore(state => state.currentTheme)
  const viewportSize = useStore(state => state.viewportSize)
  const { scrollY } = useViewportScroll(0)
  const yRange = [0, useStore(s => s.viewportSize.height) * 2]
  const scaleRange = [1, 1.2]
  const xRange = [0, -useStore(s => s.viewportSize.width)]
  const xRange2 = [-useStore(s => s.viewportSize.width), 0]
  const scaleValue = useTransform(scrollY, yRange, scaleRange)
  const xValue = useTransform(scrollY, yRange, xRange)
  const xValue2 = useTransform(scrollY, yRange, xRange2)
  const yMove = useTransform(xValue, x => x * 0.08)
  const yMove2 = useTransform(xValue, x => -x)
  const canvas = useRef(null)

  useEffect(() => {
    if (!canvas.current) return
    // const ctx = canvas.current.getContext("2d")
    // ctx.fillStyle = currentTheme === "dark" ? "#000000" : "#ffffff"
    // ctx.fillRect(0, 0, viewportSize.width, viewportSize.height)
    // let shouldDraw = false
    // let last = null
    // canvas.current.addEventListener("mouseenter", e => {
    //   shouldDraw = true
    //   last = { x: e.offsetX, y: e.offsetY }
    // })

    // canvas.current.addEventListener("mousemove", e => {
    //   if (shouldDraw) {
    //     const x = e.offsetX
    //     const y = e.offsetY
    //     ctx.globalCompositeOperation = "destination-out"

    //     ctx.lineJoin = "round"
    //     ctx.moveTo(last.x, last.y)
    //     ctx.lineTo(x, y)
    //     ctx.closePath()
    //     ctx.lineWidth = 200
    //     ctx.stroke()

    //     last = { x: x, y: y }
    //   }
    // })
    // canvas.current.addEventListener("mouseleave", e => {
    //   shouldDraw = false
    // })
  }, [viewportSize.width, viewportSize.height, currentTheme])

  return (
    <div className={s.banner}>
      {/* <canvas
        className={s.canvas}
        ref={canvas}
        height={viewportSize.height}
        width={viewportSize.width}
      /> */}
      <motion.div className={s.video} style={{ y: yMove2 }}>
        <video autoPlay playsInline muted loop src={video} />
      </motion.div>
      <motion.ul
        className={s.tagline}
        variants={wrapper}
        initial="initial"
        animate="animate"
        // animate={shouldAnimate ? "animate" : {}}
      >
        {_.lines.map((line, key) => (
          <motion.li variants={outer} key={key}>
            <motion.div variants={inner} style={{ y: yMove }}>
              {line}
            </motion.div>
          </motion.li>
        ))}
      </motion.ul>
      <div className={s.headlinesWrapper}>
        <motion.div
          style={{ scale: scaleValue, x: xValue }}
          initial={{ opacity: 0, skewY: -25 }}
          animate={{ opacity: 1, skewY: 0 }}
          transition={{
            // type: "tween",
            // duration: 1,
            delay: 0.2,
            // ease: [0, 0, 0, 1],
          }}
        >
          <HeadlineHero className={s.headline} data-content={_.title}>
            {_.title}
          </HeadlineHero>
        </motion.div>
        <motion.div style={{ scale: scaleValue, x: xValue2 }}>
          <HeadlineHero className={s.headline} data-content={_.title}>
            {_.title}
          </HeadlineHero>
        </motion.div>
      </div>
    </div>
  )
}

HomeBanner.propTypes = {
  yEnd: PropTypes.number,
}

export default HomeBanner
