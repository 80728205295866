import * as React from "react"

import Layout from "components/Layout"
import Container from "components/Container"

import HomeBanner from "./HomeBanner"

const HomePage = () => {
  return (
    <Layout>
      <HomeBanner />
      {/* <Container></Container> */}
      <div style={{ height: "100vh" }} />
    </Layout>
  )
}

export default HomePage
